class Flowbox extends HTMLElement {
  constructor() {
    super();
    this.initFlowbox = this.initFlowbox.bind(this);
    this.loadFlowboxScript = this.loadFlowboxScript.bind(this);
    this.initializeShopifyEditorEvents = this.initializeShopifyEditorEvents.bind(this);

    this.addFlowboxItemsObserver();
  }

  addFlowboxItemsObserver() {
    const flowBoxItemsObserver = new MutationObserver((mutations, observer) => {
      for (const mutation of mutations) {
        if (mutation.addedNodes && mutation.addedNodes.length) {
          // If a child has been added to Flowbox meaning there is content
          observer.disconnect(); // Stop observing
          this.closest('.shopify-section').classList.remove('tw-h-0');
          this.closest('.shopify-section').classList.remove('tw-opacity-0');
          continue;
        }
      }
    });

    // call `observe()`, passing it the element to observe, and the options object
    flowBoxItemsObserver.observe(this, {
      childList: true,
    });
  }

  // Load the Flowbox script if it's not already loaded
  loadFlowboxScript() {
    return new Promise((resolve, reject) => {
      if (document.getElementById('flowbox-js-embed')) {
        resolve();
      } else {
        (function (d, id) {
          if (!window.flowbox) {
            var f = function () {
              f.q.push(arguments);
            };
            f.q = [];
            window.flowbox = f;
          }
          if (d.getElementById(id)) {
            return;
          }
          var s = d.createElement('script'),
            fjs = d.scripts[d.scripts.length - 1];
          s.id = id;
          s.async = true;
          s.src = 'https://connect.getflowbox.com/flowbox.js';
          s.onload = resolve;
          s.onerror = reject;
          fjs.parentNode.insertBefore(s, fjs);
        })(document, 'flowbox-js-embed');
      }
    });
  }

  // Initialize Flowbox
  initFlowbox() {
    if (window.flowbox) {
      this.innerHTML = '';
      window.flowbox('init', {
        container: this,
        key: this.getAttribute('data-flowbox-key'),
        locale: this.getAttribute('data-flowbox-locale'),
        productId: this.getAttribute('data-product-id') || undefined,
        tags: this.getAttribute('data-tags') ? JSON.parse(this.getAttribute('data-tags')) : undefined,
        tagsOperator: this.getAttribute('data-tags-operator') || undefined,
      });
    }
  }

  // Add Shopify editor event listeners
  initializeShopifyEditorEvents() {
    document.addEventListener('shopify:section:load', () => {
      this.initFlowbox();
    });
  }

  // This runs when the component is added to the DOM
  connectedCallback() {
    this.loadFlowboxScript()
      .then(this.initFlowbox)
      .catch((err) => console.error('Failed to load Flowbox script:', err));
  }
}

customElements.define('flowbox-flow', Flowbox);
